import * as React from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid
  } from "@coolblue-development/becky";
import { Paper } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector, useDispatch } from "react-redux";
import { selectFormdata, addMessage, alterMessageButtonLoading } from "../../Form/FormSlice";
import anotherQuestion from '../BackendAPI/anotherQuestion'
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';

const MessageBar = (props) => {
    const dispatch = useDispatch();
    const formData = useSelector(selectFormdata);
    const messages = formData.messages;
    const messageHistory = formData.messageHistory;
    const messageButtonLoading = formData.messageButtonLoading;
    const messageArray = Object.values(messages);

    const [input, setInput] = React.useState("");
    const auth = useAuth()
    const token = auth.user.token;
    const handleSend = () => {
        let data_entry = { id: messages.length + 1, text: input, sender: "user" }
        dispatch(addMessage(data_entry));
        anotherQuestion(input, messageHistory, messages, dispatch, token)
        setInput("");
    }

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

  return (
  <Box sx={{ p: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <TextField
          fullWidth
          placeholder="Type a message"
          value={input}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          endIcon={<SendIcon />}
          onClick={handleSend}
          loading={messageButtonLoading}
        >
          Send
        </Button>
      </Grid>
    </Grid>
  </Box>
  );
};

const Message = ({ message }) => {
  const isBot = message.sender === "bot";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isBot ? "flex-start" : "flex-end",
        mb: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 1,
          backgroundColor: isBot ? "primary.light" : "secondary.light",
        }}
      >
        <Typography variant="body1">{message.text}</Typography>
      </Paper>
    </Box>
  );
};

export default MessageBar;