import { createSlice } from '@reduxjs/toolkit'

export const formDataSlice = createSlice({
  name: 'formData',
  initialState: {
    value: {
        campaignId: "",
        startDate: "",
        endDate: "",
        testType: "precart",
        truncate: 'no',
        metricType: 0,
        metrics: [{metricName: 'ConversionRate', observationType: 'all', successesType: 'custom', sucBigQueryFieldOne: 'order_placed', sucExpressionOne: '^1$'}],
        continuousMetrics: [{metricName: 'TransactionMarginperorder', observationType: 'custom', successesType: 'custom', obsBigQueryField: "CASE WHEN invoiced_order_id IS NOT NULL then '1'END", obsExpression: '^1$', sucBigQueryFieldOne: 'transaction_margin', sucExpressionOne: 'transaction_margin'}],
        dimensions: [{dimensionDefinition: "'total'", dimensionAlias: "Total"}],
        activeMetric: {},
        activeDimension: {},

        helperText: 'Block',

        username: "R2D2",

        customerContact: {toggle: 'no', startDate: '', endDate: '', contactType: 'logged', reasons: ''},
        returnRatio: {toggle: 'no', startDate: '', endDate: '', returnType: 'product_orders', reasons: ''},

        sampleSize: {type:'', metric:'', traffic:'1000', mde:'', device:'', URL:'', event_name:'', context:'', feature:'', sub_feature:''},

        messages : [
        { id: 1, text: "Hi, do you have any additional questions regarding this analysis.", sender: "bot" }
        ],
        messageButtonLoading: false,
        messageHistory: [],
        ai_analysis_looker_url: "https://coolblue.cloud.looker.com/embed/dashboards/1142?Split+Test+Name=&Segment+1=total&Metric+Name=Add+to+cart+rate%2CBounce+rate%2CPlaced+order+conversion+rate%2CTransaction+margin+per+order%2CTransaction+margin+per+visit&Campaign+ID=",
        AIAnalysisCampaign_id: "template"
    }
  },
  reducers: {
    updateCampaignId: (state, action) => {state.value.campaignId = action.payload},
    updateStartDate: (state, action) => {state.value.startDate = action.payload},
    updateEndDate: (state, action) => {state.value.endDate = action.payload},
    updateTestType: (state, action) => {state.value.testType = action.payload; state.value.helperText = 'Block';},
    updateTruncate: (state, action) => {state.value.truncate = action.payload},
    updateMetricType: (state, action) => {state.value.metricType = action.payload; state.value.helperText = 'Block';},
    updateActiveMetric: (state, action) => {state.value.activeMetric = action.payload},
    updateActiveDimension: (state, action) => {state.value.activeDimension = action.payload},

    addMetric: (state, action) => {state.value.metrics = [...state.value.metrics, action.payload]},
    addContinuousMetric: (state, action) => {state.value.continuousMetrics = [...state.value.continuousMetrics, action.payload]},

    removeMetric: (state, action) => {
        const newArray = state.value.metrics.slice()
        const metric = newArray.find( metric => metric.metricName === action.payload)
        const itemIndex = newArray.indexOf(metric)
        newArray.splice(itemIndex, 1)
        state.value.metrics =  newArray
    },

    removeContinuousMetric: (state, action) => {
        const newArray = state.value.continuousMetrics.slice()
        const metric = newArray.find( metric => metric.metricName === action.payload)
        const itemIndex = newArray.indexOf(metric)
        newArray.splice(itemIndex, 1)
        state.value.continuousMetrics =  newArray
    },

    addDimension: (state, action) => {state.value.dimensions = [...state.value.dimensions, action.payload]},
    removeDimension: (state, action) => {

        const newArray = state.value.dimensions.slice()
        const dimension = newArray.find( dimension => dimension.dimensionAlias === action.payload)
        const itemIndex = newArray.indexOf(dimension)
        newArray.splice(itemIndex, 1)
        state.value.dimensions =  newArray
    },

    updateCustomerContact: (state, action) => {state.value.customerContact = action.payload},
    updateReturnRatio: (state, action) => {state.value.returnRatio = action.payload},

    updateHelperText: (state, action) => {state.value.helperText = action.payload},
    updateUsername: (state, action) => {state.value.username = action.payload},

    addMessage: (state, action) => {state.value.messages = [...state.value.messages, action.payload]},
    alterMessageButtonLoading: (state, action) => {state.value.messageButtonLoading = action.payload},
    alterAIAnalysisCampaign_id: (state, action) => {state.value.AIAnalysisCampaign_id = action.payload},
    addMessageToHistory: (state, action) => {state.value.messageHistory = [...state.value.messageHistory, action.payload]},

  },
})

export const {
    updateCampaignId,
    updateStartDate,
    updateEndDate,
    updateTestType,
    updateTruncate,
    updateMetricType,
    addMetric,
    addContinuousMetric,
    removeMetric,
    removeContinuousMetric,
    updateActiveMetric,

    addDimension,
    updateActiveDimension,
    removeDimension,

    updateCustomerContact,
    updateReturnRatio,

    updateUsername,
    updateHelperText,

    addMessage,
    alterMessageButtonLoading,
    addMessageToHistory,
    alterAIAnalysisCampaign_id
    } = formDataSlice.actions



// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
//export const selectFormdata = (state) => state.formData.value
export const selectFormdata = (state) => state.formData.value



//export default {activeDataSliceReducer, formDataSliceReducer, selectActiveData, selectFormdata}
export default formDataSlice.reducer