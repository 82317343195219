import React, { Component } from "react"
import { Box, Button, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import {
    updateCustomerContact
} from '../../../Form/FormSlice'

export default function CustomerContact(inputCustomerContactData) {
      const dispatch = useDispatch();
      const [customerContactData, setCustomerContactData] = React.useState({inputCustomerContactData});
      const [toggle, setToggle] = React.useState(customerContactData.toggle);
      const [contactType, setContactType] = React.useState(customerContactData.contactType);
      const handleToggleChange = (event) => {setToggle(event.target.value);};
      const handleContactTypeChange = (event) => {setContactType(event.target.value);};

      const handleFormChange = (event) => {
        const key = event.target.name;
        const value = event.target.value
        if (key==='toggle') {handleToggleChange(event)}
        if (key==='contactType') {handleContactTypeChange(event)}
        const newData = customerContactData
        newData[key] = value;
        setCustomerContactData(newData);
     };

    function getSelectorValue(name, default_value) {
        if (customerContactData[name] !== undefined) {
            return customerContactData[name]
        } else if (inputCustomerContactData[name] !== undefined) {
            return inputCustomerContactData[name]
        } else {

            return default_value
        }
    }

     function getCorrectContactRatioData() {
      const copyCustomerContactData = {...customerContactData}
      const activeCustomerContactCopy = {...copyCustomerContactData.inputCustomerContactData}
      const newDict = {}


       Object.keys(activeCustomerContactCopy).forEach((item) => {
            if( item !== "inputCustomerContactData") {
                console.log(newDict, activeCustomerContactCopy[item], item)
                newDict[item] = activeCustomerContactCopy[item]
            }
        })
      Object.keys(copyCustomerContactData).forEach((item) => {
            if(item !== "inputCustomerContactData") {
            console.log(newDict, copyCustomerContactData[item], item)
                newDict[item] = copyCustomerContactData[item]
            }
        })
     console.log(newDict)
      return newDict
    }

     function handleSubmit() {
          const activeContactRatioData = getCorrectContactRatioData()
          dispatch(updateCustomerContact(activeContactRatioData))
    };

    function FormFieldBuilder(props) {
        const inputLabelId = props.name + 'label'
        switch(props.type) {
            case 'select':
                return(
                  <FormControl fullWidth>
                    <InputLabel id={props.inputLabelId}>{props.label}</InputLabel>
                    <Select
                      labelId={props.inputLabelId}
                      name={props.name}
                      defaultValue={inputCustomerContactData[props.name]}
                      value={getSelectorValue(props.name, props.default_value)}
                      label={props.label}
                      onChange={(e) => { handleFormChange(e);
                      ;}}>

                      {Object.entries(props.options).map(([key,value])=>
                        <MenuItem value={key}>{value}</MenuItem>
                      )};
                    </Select>
                  </FormControl>)
            case 'textfield':
                return(

                 <TextField name={props.name} label={props.label} placeholder={props.placeholder}
                    defaultValue={inputCustomerContactData[props.name]}
                    value={customerContactData[props.name]}
                    onChange={(e) => {handleFormChange(e);}}/>
                 )
            }
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography variant="h2" gutterBottom component="div">
              Customer Contacts
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} paddingBottom={4} paddingTop={4}>
            <Grid item xs={2} paddingBottom={4}>
            <Box paddingRight={4}>
                <FormFieldBuilder
                    { ...{
                        type: 'select', name: 'toggle', default_value: 'no', label: 'Analyze customer contacts',
                        options:
                            {no: 'No',
                            all: 'Yes, on all contact reasons',
                            specific: 'Yes, on specific set of contact reasons'}
                            }}/>
                </Box>
            </Grid>
        </Grid>
        {getSelectorValue('toggle', 'no') !== 'no' && (
        <Grid container xs={12}>
        <Grid item xs={2} paddingBottom={4}>
            <FormFieldBuilder {...{type: 'textfield', name: 'startDate', label: 'Start Date', placeholder: 'e.g. 2023-01-01'}} />
        </Grid>
        <Grid item xs={2} paddingBottom={4}>
            <FormFieldBuilder {...{type: 'textfield', name: 'endDate', label: 'End Date', placeholder: 'e.g. 2023-01-01'}} />
        </Grid>
        <Grid item xs={12} paddingBottom={4} paddingTop={4}>
            <Grid item xs={2} paddingBottom={4}>
            <Box paddingRight={4}>
                <FormFieldBuilder
                    { ...{
                        type: 'select', name: 'contactType', default_value: 'logged', label: 'Contact ratio calculation',
                        options:
                            {logged: 'Unique logged contacts per reason/visits',
                            estimated: 'Unique estimated contacts per reason/visits',
                            orders: 'Unique orders with contacts/orders'}
                            }}/>
                </Box>
            </Grid>
        </Grid>
        </Grid>)}
        {getSelectorValue('toggle', 'no') === 'specific' && (
        <Grid container xs={12}>
        <Grid item xs={2} paddingBottom={4}>
            <FormFieldBuilder {...{type: 'textfield', name: 'contactReasons', label: 'Contact Reasons', placeholder: '1234|134564|1234'}} />
        </Grid>
        </Grid>)}
          <Grid container>
            <Grid item xs={3}>
                <Box>
                    <Button preset="callToAction" onClick={() => handleSubmit()}>Save</Button>
                </Box>
                <Grid item xs={10} paddingBottom={4} paddingTop={4}>
                <Typography sx={{ fontSize: 13, color: '#999999' }}>
                    Please save after you filled in this tab!
                </Typography>
        </Grid>
            </Grid>
        </Grid>
      </Grid>
    )
}