import React, { Component } from "react"
import { Box, Button, Divider, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";
import AddDimension from '../Components/AddDimension/AddDimension'
import Login from "../../SharedComponents/Login";
import { useSelector, useDispatch } from 'react-redux'
import {
  selectFormdata,
} from '../../Form/FormSlice'


import trackPageview from "../../Analytics/useAnalyticsEventTracker";

export default function CustomMetric() {
    trackPageview()
    const formData = useSelector(selectFormdata);
    const activeDimension = formData.activeDimension

    return (
    <AddDimension {...activeDimension} />
    )
}