import { addMessageToHistory } from "../../Form/FormSlice";


export default function useRun(details, setLoading, setAnalysis, dispatch, token) {
        setLoading(true)
        let domain;
        if (!process.env.REACT_APP_AI_BACKEND_HOST_URL) {
            domain = 'http://127.0.0.1:5555';
        } else {
            domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        }
        let backendHostUrl = domain + "/run"

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(details),
        };
        fetch(backendHostUrl, requestOptions)
            .then(res => res.json())
            .then(function(result) {
                dispatch(addMessageToHistory({'question': result.question, 'answer': result.answer}))
                setAnalysis(result.answer)
        })
            .finally(() => setLoading(false))
}