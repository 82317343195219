import { Box, IconButton, Typography } from "@coolblue-development/becky";
import React, { Component, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectFormdata, updateHelperText } from "../../Form/FormSlice";
import { Becky } from "@coolblue-development/icons";
import {
	testTypeVisitsSupport,
	testTypeDatasetsSupport,
	metricTypeSupport,
} from "../../Form/StandardMetricsDimsMap";
import {
	CbColorBackgroundSubtle,
	CbColorBorderFocusInner,
} from "@coolblue-development/design-tokens";
import { QuestionmarkCircled, Cross } from "@coolblue-development/icons";

export default function ColumnSelectionHelper(inputMarginBottom) {
	const formData = useSelector(selectFormdata);

	const metricType = formData.metricType;
	const testType = formData.testType;
	const testTypeVisitsSupportText = testTypeVisitsSupport[testType];
	const testTypeDatasetsSupportText = testTypeDatasetsSupport[testType];
	const metricTypeSupportText = metricTypeSupport[metricType];
	const showHelperText = formData.helperText;
	const dispatch = useDispatch();

	function handleClose() {
		dispatch(updateHelperText("None"));
	}

	return (
		<Box
			sx={{
				background: "#f2f7fc",
				borderRadius: 2,
				padding: 4,
				border: "solid 1px #0090e3",
				display: showHelperText,
				position: "relative",
				marginBottom: { inputMarginBottom },
			}}
		>
			<IconButton
				preset="text"
				onClick={() => handleClose()}
				size="small"
				aria-label="Close dialog"
				sx={{ position: "absolute", top: "2px", right: "2px" }}
			>
				<Cross />
			</IconButton>
			<Typography>
				{" "}
				{testTypeVisitsSupportText} {testTypeDatasetsSupportText}{" "}
				{metricTypeSupportText}{" "}
			</Typography>
		</Box>
	);
}
