import React, { Component } from "react"
import { Box, Button, Divider, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import AddMetric from '../Components/AddMetric/AddMetric'
import Login from "../../SharedComponents/Login";
import { useSelector, useDispatch } from 'react-redux'
import trackPageview from "../../Analytics/useAnalyticsEventTracker";
import {
  selectFormdata,
} from '../../Form/FormSlice'

export default function CustomMetric() {
    trackPageview()
    const formData = useSelector(selectFormdata);
    const activeMetric = formData.activeMetric
    return (
        <AddMetric {...activeMetric} />
    )
}