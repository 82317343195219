export const boxStyle = {
    borderRadius: 2,
    padding: 4,
    border:"1px solid hsla(203, 50%, 30%, 0.15)",
    margin: 4,
    boxShadow: "rgba(0, 0, 0, 0.10) 0 1px 3px 0",
    backgroundColor: "#fff"
};

export default {boxStyle};



