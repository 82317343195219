import React from "react"
import { Box, Chip, DataGrid } from "@coolblue-development/becky"
import { Cross, Plus } from '@coolblue-development/icons';
import Edit from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux'
import { LicenseInfo } from '@mui/x-license-pro';
import {red, green, blue} from '../../../SharedComponents/Colors'
import { useNavigate } from "react-router-dom";
import {
  selectFormdata,
    addMetric,
    addContinuousMetric,
    updateActiveMetric,
    removeMetric,
    removeContinuousMetric
} from '../../../Form/FormSlice'

LicenseInfo.setLicenseKey('38972342a2b4eb8c2fc46e8abd2969f3Tz03NDgyOCxFPTE3MjY0Nzk0ODIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

function getChipProps(params: GridRenderCellParams): ChipProps {

  if (params.value === "Delete") {
    return {
      icon: <Cross  style={{ fill: red }}/>,
      label: params.value,
     style: {
        borderColor: red
      }
    };
  }
  else if (params.value === "Copy"){
    return {
      icon: <Plus style={{ fill: green }}/>,
      label: params.value,
     style: {
        borderColor: green
      }
    };
  }
    else if (params.value === "Edit"){
    return {
      icon: <Edit style={{ fill: blue }}/>,
      label: params.value,
     style: {
        borderColor: blue
      }
    };
  }
}


export default function MetricsList() {
    const formData = useSelector(selectFormdata);
    var metricsArray = [];
    if (formData.metricType === 0) {
        metricsArray = [...formData.metrics]
        console.log()
    } else {
        metricsArray = [...formData.continuousMetrics]
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const columns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 10,
        editable: false,
      },
      {
        field: 'metricName',
        headerName: 'Metric Name',
        width: 250,
        editable: false,
      },
      {
        field: 'Edit',
        headerName: 'Edit',
        width: 150,
        renderCell: (params) => {
            return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
        },
        editable: false,
      },
      {
        field: 'Copy',
        headerName: 'Copy',
        renderCell: (params) => {
            return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
        },
        width: 150,
        editable: false,
      },
      {
        field: 'Delete',
        headerName: 'Delete',
        renderCell: (params) => {
            return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
        },
        width: 150,
        editable: false,
      }
    ];

    const detailsrows = metricsArray.map((row, index) => {
        return {
            id: index + 1,
            metricName: row.metricName,
            Edit: 'Edit',
            Copy: 'Copy',
            Delete: 'Delete',
        }
    })


    function handleOnCellClick(params) {
        const metric = metricsArray.find( metric => metric.metricName === params.row.metricName);
        if(params.field==='Copy') {
            const metricCopy = {...metric}
            metricCopy.metricName = metricCopy.metricName + '_copy'
            if (formData.metricType === 0) {
                dispatch(addMetric(metricCopy))
            } else {
                dispatch(addContinuousMetric(metricCopy))
            }
        } else if (params.field === 'Delete') {
            if (formData.metricType === 0) {
                dispatch(removeMetric(params.row.metricName))
            } else {
                dispatch(removeContinuousMetric(params.row.metricName))
            }
        } else if (params.field === 'Edit') {
            dispatch(updateActiveMetric(metric))
            navigate("/CustomAnalysis_GA4/CustomMetric");
        };
    }

    return (
    <Box>
      <DataGrid
        autoHeight
        rows={detailsrows}
        columns={columns}
        onCellClick={handleOnCellClick}
        initialState = {
			{
				columns: {
					columnVisibilityModel: {
						id: false,
					},
				},
			}
		}
      />
    </Box>
    );
}
