import React, { Component, useState, useEffect } from "react"
import { Box, Chip, Button, DataGrid, FormControl, Grid, MenuItem, InputLabel, Select, TextField, Typography } from "@coolblue-development/becky"
import { GridToolbar } from '@mui/x-data-grid';
import Edit from '@mui/icons-material/Edit';
import {red, green, blue, gray, peppermint, antarcticblue} from '../../SharedComponents/Colors'
import { useSelector, useDispatch } from 'react-redux'
import { LicenseInfo } from '@mui/x-license-pro';
import { useNavigate } from "react-router-dom";
import  fetchTests  from '../BackendAPI/fetchOverviewData'
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';


LicenseInfo.setLicenseKey('38972342a2b4eb8c2fc46e8abd2969f3Tz03NDgyOCxFPTE3MjY0Nzk0ODIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

function getChipProps(params: GridRenderCellParams): ChipProps {
    if (params.value === "Edit"){
    return {
      icon: <Edit style={{ fill: blue }}/>,
      label: params.value,
     style: {
        borderColor: blue
      }
    };
  }
}

function getFinished(params) {
  let end_date = new Date(params.row.end_date)
  let today = new Date()
  if (end_date <= today) {
    return "Finished"
  }
  else {
    return "Not finished"
  }
}

export default function TestOverview() {
    const navigate = useNavigate();
    const [tests, setTests] = useState([])
    const [loading, setLoading] = useState(false)
    const auth = useAuth()
    const token = auth.user.token;
    fetchTests(tests, setTests, loading, setLoading, token)

    const columns = [
      {
        field: 'id',
        headerName: 'ID',
        width: 10,
        editable: false,
      },
      {
        field: 'campaignName',
        headerName: 'Campaign Name',
        width: 250,
        editable: false,
      },
      {
        field: 'campaignId',
        headerName: 'CampaignId',
        width: 175,
        editable: false,
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 175,
        renderCell: (params) => {
            if (params.value) {
                return <strong style={{ color: green }} > {params.value.toString()}</strong>;
            }
            else  {
                return <strong style={{ color: gray }} > {params.value.toString()}</strong>;
            }
        },
        editable: false,
      },
      {
        field: 'domain',
        headerName: 'domain',
        width: 250,
        editable: false,
      },
      {
        field: 'label',
        headerName: 'label',
        width: 250,
        editable: false,
      },
      {
        field: 'start_date',
        headerName: 'Start date',
        width: 175,
        editable: false,
      },
      {
        field: 'end_date',
        headerName: 'End date',
        width: 175,
        editable: false,
      },
      {
        field: 'finished',
        headerName: 'Finished',
        width: 175,
        valueGetter: getFinished,
        renderCell: (params) => {
            if (params.value ==='Finished') {
                return <strong style={{ color: green }} > {params.value}</strong>;
            }
            else  {
                return <strong style={{ color: gray }} > {params.value}</strong>;
            }
        },
      },
      {
        field: 'impact',
        headerName: 'Impact',
        width: 175,
        editable: false
      }
    ];


    const detailsrows = tests.map((row, index) => {
        return {
            id: index + 1,
            campaignName: row.name,
            campaignId: row.id,
            active: row.active,
            domain: row.domain,
            label: row.label,
            start_date: row.start_date,
            end_date: row.end_date,
            impact: parseInt(row.impact ?? 0)
        }
    })

    function handleOnCellClick(params) {
        let campaignId = params.row.campaignId
        navigate("/TrackingConfigurator/Details?id=" + campaignId);
    }

    return (
    <Box>
        <DataGrid
            autoHeight
            rows={detailsrows}
            columns={columns}
            onCellClick={handleOnCellClick}
            initialState = {
                {
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            domain: false,
                            label: false
                        },
                    },
                    sorting:
                    {
                        sortModel: [{ field: 'start_date', sort: 'desc' }],
                    }
            }}
            slots={{ toolbar: GridToolbar }}
        />
    </Box>
    );
}
