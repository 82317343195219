import React, { Component, useState, useEffect } from "react"
import { Autocomplete, Box, Chip, Checkbox, Button, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, MenuItem, InputLabel, Select, Tab, Tabs, TabPanel, TextField, Typography } from "@coolblue-development/becky"
import Edit from '@mui/icons-material/Edit';
import {red, green, blue, gray, peppermint, antarcticblue} from '../../SharedComponents/Colors'
import dayjs from 'dayjs';
import ListItemText from '@mui/material/ListItemText';
import { useSelector, useDispatch } from 'react-redux'
import { LicenseInfo } from '@mui/x-license-pro';
import { useNavigate, NavLink } from "react-router-dom";
import  fetchDetails from '../BackendAPI/fetchDetailsData'
import  Run from '../BackendAPI/Run'
import  Save from '../BackendAPI/Save'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import Switch from '@mui/material/Switch';
import { selectFormdata, addMessageToHistory } from "../../Form/FormSlice";
import { metricsArray } from "../../Form/StandardMetricsDimsMap";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';

LicenseInfo.setLicenseKey('38972342a2b4eb8c2fc46e8abd2969f3Tz03NDgyOCxFPTE3MjY0Nzk0ODIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


export default function TestDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState(false);
    const [campaignIdHelpertext, setCampaignIdHelpertext] = useState('')

    const [data, setData] = useState(null);
    const [analysis, setAnalysis] = useState('')
    const [question, setQuestion] = useState('')
    const [metricValue, setMetricValue] = React.useState([]);

    const auth = useAuth()
    const token = auth.user.token;
    const formData = useSelector(selectFormdata);
    let campaign_id = formData.AIAnalysisCampaign_id
    fetchDetails(setDetails, setMetricValue, setLoading, token, campaign_id)

    const url = formData.ai_analysis_looker_url;
	const messageHistory = formData.messageHistory;

    const [value, setValue] = React.useState(0);
	const handleTabChange = (_event: SyntheticEvent, newValue: number): void => {
		setValue(newValue);
	};

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        setDetails((prevState) => ({
            ...prevState,
            [target.name]: target.value,
        }));
        if (target.name === 'active') {
            setDetails((prevState) => ({
                ...prevState,
                [target.name]: target.checked,
            }));
        }
        console.log(details)
    };

    const handleStartDateChange = (newValue): void => {
        setDetails((prevState) => ({
            ...prevState,
            ['start_date']: dayjs(newValue, 'YYYY-MM-DD').toDate(),
        }));
    };

    const handleEndDateChange = (newValue): void => {
        setDetails((prevState) => ({
            ...prevState,
            ['end_date']: dayjs(newValue, 'YYYY-MM-DD').toDate(),
        }));
    };

    const changeCampaignIdHelpertext = (event) => {
        const { target: { value }, } = event;
        const regex = /^[a-zA-Z0-9]{6}$/g;
        const found = value.match(regex);
        if (!found) {
            setCampaignIdHelpertext('Are you sure this is valid?')
        }
        else {
            setCampaignIdHelpertext('')
        }
    };

    function RunStore(details, setLoading, setAnalysis, token) {
        Run(details, setLoading, setAnalysis, dispatch, token)
    }

    return (
    <>
        <Tabs value={value} onChange={handleTabChange}>
            <Tab label="Configuration" />
            <Tab label="Looker Dashboard" />
        </Tabs>
        <TabPanel value={value} index={0}>
            <Grid container>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h2" gutterBottom component="div">
                            General information
                        </Typography>
                    </Box>
                </Grid>
                <Grid container xs={12} paddingTop={4} paddingBottom={4}>
                    <Grid item xs={4} paddingRight={6}>
                        <TextField fullWidth
                           name="campaign_name"
                           label="Campaign name"
                           placeholder="e.g. 'Beste idee evah ;)'"
                           value={details.campaign_name}
                           onChange={handleChange}
                        />
                    </ Grid>
                    <Grid item xs={2} paddingBottom={4}>
                        <TextField
                           name="campaign_id"
                           label="CampaignId"
                           placeholder="e.g. '1s4g41'"
                           helperText={campaignIdHelpertext}
                           value={details.campaign_id}
                           onChange={e => { handleChange(e); changeCampaignIdHelpertext(e) }}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} paddingBottom={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item xs={2} paddingRight={6}>
                            <DatePicker
                                name="start_date"
                                label="Start date"
                                value={dayjs(details.start_date)}
                                onChange={(newValue) => handleStartDateChange(newValue)}
                            />
                        </Grid>
                        <Grid item xs={2} paddingRight={6}>
                            <DatePicker
                                label="End date"
                                value={dayjs(details.end_date)}
                                onChange={(newValue) => handleEndDateChange(newValue)}
                            />
                        </Grid>
                    </ LocalizationProvider>
                </Grid>
                <Grid item xs={12} paddingTop={4} paddingBottom={4}>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h2" gutterBottom component="div">
                            Experiment setup
                        </Typography>
                    </Box>
                </Grid>
                <Grid container xs={6} paddingBottom={4} paddingTop={4}>
                <Autocomplete fullWidth
                  multiple
                  name="metrics"
                  value={metricValue}
                  onChange={(event, newValue) => {
                    setMetricValue(newValue);
                    setDetails((prevState) => ({
                        ...prevState,
                        ['metrics']: newValue,
                    }));
                  }}
                  options={metricsArray}
                  filterSelectedOptions
                  renderInput={(params: AutocompleteRenderInputParams): ReactNode => (
                    <TextField {...params} label="Metric" placeholder="Type to filter…" />
                  )}
                />
                </Grid>
                <Grid container xs={12} paddingBottom={4} paddingTop={4}>
                    <Grid item xs={12} paddingRight={6}>
                        <TextField fullWidth
                            label="Hypothesis"
                            name="hypothesis"
                            value={details.hypothesis}
                            onChange={e => { handleChange(e)}}
                        />
                    </ Grid>
                </Grid>
                <Grid container xs={12} paddingBottom={4} paddingTop={4}>
                    <Grid item xs={12} paddingRight={6}>
                        <TextField fullWidth
                            multiline
                            label="Test description"
                            name="description"
                            value={details.description}
                            maxRows={10}
                            onChange={e => { handleChange(e)}}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} paddingBottom={4} paddingTop={4}>
                    <Grid item xs={12} paddingRight={6}>
                        <TextField fullWidth
                            multiline
                            label="Analysis"
                            name="analysis"
                            value={analysis}
                            maxRows={10}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} paddingBottom={4}>
                    <Grid item xs={2} paddingTop={4}>
                        <Box>
                            <Button
                                preset="callToAction"
                                name="run"
                                onClick={() => RunStore(details, setLoading, setAnalysis, token)}
                                loading={loading}
                            >
                                Analyze my experiment
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={2} paddingTop={4}>
                        <Box>
                            <Button
                                preset="callToAction"
                                name="save"
                                onClick={() => Save(details, setLoading, token)}
                                loading={loading}
                                disabled={details.campaign_id === 'template'}
                            >
                                Save my changes
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={2} paddingTop={4}>
                        <Box>
                            <Button
                                component={NavLink}
                                to="/">
                                Return to overview
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <iframe
                src={url + details.campaign_id}
                width="100%"
                height="700px"
                frameborder="0">
            </iframe>
        </TabPanel>
    </>
    );
}
