import { configureStore } from '@reduxjs/toolkit'
import formDataReducer from '../Form/FormSlice'


export default configureStore({
  reducer: {
    formData: formDataReducer,
  },
});

