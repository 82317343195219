export const routes = {
    ROOT: '/',
    LOGIN: '/Login',
    LOGOUT: '/logout',
    CUSTOM_ANALYSIS: '/CustomAnalysis',
    CUSTOM_ANALYSIS_GA4: '/CustomAnalysis_GA4',
    CUSTOM_ANALYSIS_CUSTOMMETRIC: 'CustomAnalysis/CustomMetric',
    CUSTOM_ANALYSIS_GA4_CUSTOMMETRIC: 'CustomAnalysis_GA4/CustomMetric',
    CUSTOM_ANALYSIS_STANDARDMETRIC: 'CustomAnalysis/StandardMetric',
    CUSTOM_ANALYSIS_GA4_STANDARDMETRIC: 'CustomAnalysis_GA4/StandardMetric',
    CUSTOM_ANALYSIS_CUSTOMDIMENSION: 'CustomAnalysis/CustomDimension',
    CUSTOM_ANALYSIS_GA4_CUSTOMDIMENSION: 'CustomAnalysis_GA4/CustomDimension',
    CUSTOM_ANALYSIS_STANDARDDIMENSION: 'CustomAnalysis/StandardDimension',
    CUSTOM_ANALYSIS_GA4_STANDARDDIMENSION: 'CustomAnalysis_GA4/StandardDimension',
    AI_ANALYSIS: '/AIAnalysis',
    AI_ANALYSIS_DETAILS: '/AIAnalysis/Details',
    TRACKING_CONFIGURATOR: '/TrackingConfigurator',
    TRACKING_CONFIGURATOR_DETAILS: '/TrackingConfigurator/Details',
    SAMPLE_SIZE_CALCULATOR: '/SampleSizeCalculator/'
};