import React, { useState }  from "react";
import {
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Divider,
	Grid,
	Tab,
	Tabs,
	TabPanel,
} from "@coolblue-development/becky";
import { Cross } from '@coolblue-development/icons';
import IconButton from '@mui/material/IconButton';
import GeneralInformation from "../Components/Generalinformation";
import Login from "../../SharedComponents/Login";
import ColumnSelectionHelper from "../Components/ColumnSelectionHelper";
import Metrics from "../Components/Metrics/Metrics";
import Dimensions from "../Components/Dimensions/Dimensions";
import CustomerContact from "../Components/CustomerContact/CustomerContact";
import ReturnRatio from "../Components/ReturnRatio/ReturnRatio";
import { useSelector } from "react-redux";
import RunQueries from "../Submit/Submit";
import { selectFormdata } from "../../Form/FormSlice";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import trackPageview from "../../Analytics/useAnalyticsEventTracker";

export default function Home() {
    trackPageview()

	const [value, setValue] = React.useState(0);
	const handleTabChange = (_event: SyntheticEvent, newValue: number): void => {
		setValue(newValue);
	};

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const handleClose = (): void => {
      setOpen(false);
    };

	const [loading, setLoading] = React.useState(false);

	const formData = useSelector(selectFormdata);
	const customerContactData = formData.customerContact;
	const returnRatioData = formData.returnRatio;

    const auth = useAuth()
    const token = auth.user.token;

    return (
        <>
        <Box>
            <GeneralInformation />
            <ColumnSelectionHelper marginBottom={0} />
            <Tabs value={value} onChange={handleTabChange}>
                <Tab
                    label="Metrics"
                    disabled={
                        formData.testType === "return_ratio" ||
                        formData.testType === "contact_ratio" ||
                        formData.testType === "contact_ratio_after_order" ||
                        formData.testType === "contact_ratio_self_service" ||
                        formData.testType === "return_ratio_after_order"
                    }
                />
                <Tab label="Dimensions" />
                <Tab
                    label="Return Ratio"
                    disabled={
                        formData.testType !== "return_ratio" &&
                        formData.testType !== "return_ratio_after_order"
                    }
                />
                <Tab
                    label="Contact Ratio"
                    disabled={
                        formData.testType !== "contact_ratio" &&
                        formData.testType !== "contact_ratio_after_order" &&
                        formData.testType !== "contact_ratio_self_service"
                        }
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Metrics />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Dimensions />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ReturnRatio {...returnRatioData} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <CustomerContact {...customerContactData} />
            </TabPanel>
            <Box sx={{ height: 10 }} />
            <Divider />
            <Box sx={{ height: 10 }} />
            <Grid item xs={3} paddingTop={4}>
                <Box>
                    <Button
                        preset="callToAction"
                        loading={loading}
                        onClick={() =>
                            RunQueries(formData, token, setLoading, setOpen, setContent)
                        }
                    >
                        Build the dashboard
                    </Button>
                </Box>
            </Grid>
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            Your data is ready
            <IconButton
              onClick={handleClose}
              preset="text"
              size="small"
              aria-label="Close dialog"
            >
              <Cross />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{content}</DialogContentText>
          </DialogContent>
        </Dialog>
        </>
    );
}
