import React, { useState, useEffect } from "react"


export default function SaveData(details, setLoading, token) {
        setLoading(true)
        let domain;
        if (!process.env.REACT_APP_AI_BACKEND_HOST_URL) {
            domain = 'http://127.0.0.1:5555';
        } else {
            domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        }
        let backendHostUrl = domain + "/tc_save"
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(details),
        };
        fetch(backendHostUrl, requestOptions)
            .then(function (body) {
                return body.text();
            })
            .then(function (data) {
                if (data != 'success') {
                    alert(data);
                } else {
                    setLoading(false)
                    window.location.href = "/TrackingConfigurator";
                }
            })
}