import React from "react"
import { Box, Button, Grid } from "@coolblue-development/becky"
import MetricsList from "./MetricsList"
import { NavLink } from "react-router-dom";

export const MetricsContainer = (props) => {
    return (
    <Box>
        <MetricsList />
        <Box >
          <Grid container>
            <Grid item xs={3}>
              <Box>
                <Button component={NavLink} to="/CustomAnalysis/CustomMetric" >Add a custom metric</Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box>
                    <Button component={NavLink} to="/CustomAnalysis/StandardMetric">Add a standard metric</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
    </Box>
    );
}

export default MetricsContainer;