import React, { useState } from "react";
import {
	Box,
	Button,
	Chip,
	DataGrid,
	Grid,
	Typography,
} from "@coolblue-development/becky";
import {
	Plus
} from "@coolblue-development/icons";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import {green} from '../../SharedComponents/Colors'
import Login from "../../SharedComponents/Login";
import { NavLink } from "react-router-dom";
import {
	selectFormdata,
	addMetric,
	addContinuousMetric
} from "../../Form/FormSlice";
import {
	metricsDefinition_GA4,
	initialMetrics,
} from "../../Form/StandardMetricsDimsMap";
import trackPageview from "../../Analytics/useAnalyticsEventTracker";

function getChipProps(params: GridRenderCellParams): ChipProps {
	if (params.value === "Add") {
		return {
			icon: <Plus style={{ fill: green }} />,
			label: params.value,
			style: {
				borderColor: green,
			},
		};
	}
}

export default function StandardMetric() {
    trackPageview()
	const dispatch = useDispatch();
	const formData = useSelector(selectFormdata);
	const addedMetrics = formData.metrics;
	const columns = [
		{
			field: "id",
			headerName: "ID",
			width: 10,
			editable: false,
		},
		{
			field: "metricName",
			headerName: "Metric Name",
			width: 250,
			editable: false,
		},
		{
			field: "Add",
			headerName: "Add",
			renderCell: (params) => {
				return (
					<Chip variant="outlined" size="small" {...getChipProps(params)} />
				);
			},
			width: 150,
			editable: false,
		},
	];

	function checkIfMetricIsNotAdded(metric) {
		if (addedMetrics.some((e) => e.metricName === metric)) {
			return false;
		} else {
			return true;
		}
	}

	const [metricsArray, setMetricsArray] = useState(initialMetrics);
	const metrArray = metricsArray.filter((metric) =>
		checkIfMetricIsNotAdded(metric.name)
	);

    let filteredMetrics =  metrArray.filter(metric => {
        return formData.metricType === metric.metricType && (metric.testTypes.length === 0 | metric.testTypes.indexOf(formData.testType) !== -1)
    })
	const detailsrows = filteredMetrics.map((row, index) => {
		return {
			id: row.id + 1,
			metricName: row.name,
			Add: row.action,
		};
	});

	function handleAdding(params) {
		var copyMetricsArray = [...metricsArray];
		const metric = copyMetricsArray.find(
			(metric) => metric.name === params.row.metricName
		);
		const itemIndex = copyMetricsArray.indexOf(metric);
		copyMetricsArray.splice(itemIndex, 1);
		setMetricsArray(copyMetricsArray);
		var metricDef = metricsDefinition_GA4[params.row.metricName];
        if (formData.metricType === 0) {
            dispatch(addMetric(metricDef));
        } else {
            dispatch(addContinuousMetric(metricDef));
        }
	}

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box>
                    <Typography variant="h2" gutterBottom component="div">
                        Add a standard metric
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box>
                    <DataGrid
                        autoHeight
                        rows={detailsrows}
                        columns={columns}
                        onCellClick={handleAdding}
                        initialState = {
                            {
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }
                        }
                    />
                </Box>
            </Grid>
            <Grid item xs={6} paddingTop={4} paddingBottom={4}>
                <Box>
                    <Button component={NavLink} to="/CustomAnalysis_GA4/" preset="callToAction">
                        Home
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
}