import React, { Component, useState, useEffect } from "react"

export default function useFetchTests(tests, setTests, loading, setLoading, token) {
    useEffect(() => {
        let domain;
        if (!process.env.REACT_APP_AI_BACKEND_HOST_URL) {
            domain = 'http://127.0.0.1:5555';
        } else {
            domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        }
        let backendHostUrl = domain + "/tc_tests"
        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token }
        };
        setLoading(true);
        fetch(backendHostUrl, requestOptions)
            .then(res => res.json())
            .then(result => setTests(result))
            .finally(() => setLoading(false))
    }, [])
}