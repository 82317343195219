import React, { useState }  from "react";
import {
	Box,
	Button,
	Grid,
	Typography
} from "@coolblue-development/becky";
import { useNavigate, NavLink } from "react-router-dom";
import {red, green, blue, gray, peppermint, antarcticblue} from '../../SharedComponents/Colors'
import TestOverview from "../Components/TestOverview";


export default function Home() {

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const handleClose = (): void => {
      setOpen(false);
    };
    return (
    <Grid container>
        <Grid item xs={12}>
            <Box>
                <Typography variant="h2" gutterBottom component="div">
                    Test overview
                </Typography>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box>
                <TestOverview />
            </Box>
        </Grid>
        <Grid container xs={12} paddingBottom={4}>
            <Grid item xs={2} paddingTop={4}>
                <Box>
                    <Button
                        preset="callToAction"
                        component={NavLink}
						to="/TrackingConfigurator/Details?id=new">
                        New configuration
                    </Button>
                </Box>
            </Grid>
        </Grid>
    </Grid>
    );
}
