import { AppBar, Avatar, IconButton, Drawer, MenuList, MenuItem } from "@coolblue-development/becky";
import { Menu } from '@coolblue-development/icons';
import React, { useState } from "react";
import {
    selectFormdata,
} from '../Form/FormSlice'
import {
  NavLink,
  useNavigate
} from "react-router-dom";
import { useSelector } from 'react-redux'
import { Becky, Coolblue } from '@coolblue-development/icons';
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';

export default function AppBarComponent() {
    const formData = useSelector(selectFormdata);
    const auth = useAuth();
    var appBarUsername = formData.username;

    if (!auth) {
        appBarUsername = formData.username;
    }
    else {
        appBarUsername = auth?.user?.email.split('@')[0];
    };

    const [open, setOpen] = useState(false);
    const toggleDrawer = (status: boolean): void => {
      setOpen(status);
    };

    const linkToPage = (link) => {
        window.open(link, '_blank');
    };

return (
  <>
    <AppBar
      position={"static"}
      logo={<Coolblue onClick={() => linkToPage("/")}/>}
      title={"Split Testing Toolkit"}
      avatar={<Avatar><Becky /></Avatar>}
      username={appBarUsername}
      menu={
        <IconButton
          preset="text"
          color="primary"
          contextBackground="primary"
          size="medium"
          iconSize="large"
          onClick={(): void => {
            toggleDrawer(true);
          }}
        >
          <Menu />
        </IconButton>
      }
    />
        <Drawer
      anchor="left"
      title="Menu"
      open={open}
      onClose={(): void => {
        toggleDrawer(false);
      }}
    >
      <MenuList>
          <MenuItem onClick={() => linkToPage("https://coolblue.cloud.looker.com/dashboards/1209?Campaign+ID=&Metric+Name=&Segment=total&Segment+Value=")}>Custom analysis dashboard binary metrics</MenuItem>
          <MenuItem onClick={() => linkToPage("https://coolblue.cloud.looker.com/dashboards/4610?Campaign%20ID=&Metric%20Name=&Segment=total&Segment%20Value=")}>Custom analysis dashboard continuous metrics</MenuItem>
          <MenuItem onClick={() => linkToPage("https://drive.google.com/file/d/1s04e1JoInxAXz9OmAAm3cV6toPPpWsWV/view")}>Tutorial video</MenuItem>
          <MenuItem onClick={() => linkToPage("https://coolblue.atlassian.net/wiki/spaces/KCSTAD/overview")}>Custom Analysis Confluence</MenuItem>
          <MenuItem onClick={() => linkToPage("https://coolblue.atlassian.net/wiki/spaces/KCSTAD/overview")}>Split testing Confluence</MenuItem>
          <MenuItem onClick={() => linkToPage("https://github.com/coolblue-development/gcp-applications-stad-split-testing/tree/production/custom-analysis-tool-backend/bases")}>Basis files in Github Repo</MenuItem>
        </MenuList>

    </Drawer>
  </>)
}
