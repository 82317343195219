import React, { useState, useEffect } from "react"
import { selectFormdata, addMessage, alterMessageButtonLoading, addMessageToHistory } from "../../Form/FormSlice";


export default function AnotherQuestion(input, messageHistory, messages, dispatch, token) {
        let domain;
        if (!process.env.REACT_APP_AI_BACKEND_HOST_URL) {
            domain = 'http://127.0.0.1:5555';
        } else {
            domain = process.env.REACT_APP_AI_BACKEND_HOST_URL;
        }
        let backendHostUrl = domain + "/question"
        let data = {question: input, conversation: messageHistory}
        const requestOptions = { method: "POST", headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + token }, body: JSON.stringify(data)};
        dispatch(alterMessageButtonLoading(true));
        fetch(backendHostUrl, requestOptions)
            .then(res => res.json())
            .then(function(result) {
                let response_data = { id: messages.length + 2, text: result.answer, sender: "bot" }
                dispatch(addMessage(response_data));
                dispatch(addMessageToHistory({'question': result.question, 'answer': result.answer}))
                dispatch(alterMessageButtonLoading(false));
            })
}