import React, { useState } from "react"
import { Box, Button, Chip, DataGrid, Grid, Typography } from "@coolblue-development/becky"
import { Plus } from '@coolblue-development/icons';
import { useSelector, useDispatch } from 'react-redux'
import { green } from '../../SharedComponents/Colors'
import Login from "../../SharedComponents/Login";
import { useAuth } from '@coolblue-development/react-cognito-sso-auth';
import { NavLink } from "react-router-dom";
import {
  selectFormdata,
    addDimension
} from '../../Form/FormSlice'
import {initialDimensions} from '../../Form/StandardMetricsDimsMap'
import trackPageview from "../../Analytics/useAnalyticsEventTracker";

function getChipProps(params: GridRenderCellParams): ChipProps {
  if (params.value === "Add"){
    return {
      icon: <Plus style={{ fill: green }}/>,
      label: params.value,
     style: {
        borderColor: green
      }
    };
  }
}


export default function StandardDimension() {
        trackPageview()
        const dispatch = useDispatch();
        const formData = useSelector(selectFormdata);
        const addedDimensions = formData.dimensions
        const columns = [
          {
            field: 'id',
            headerName: 'ID',
            width: 10,
            editable: false,
          },
          {
            field: 'dimensionAlias',
            headerName: 'Dimension Name',
            width: 250,
            editable: false,
          },
          {
            field: 'dimensionDefinition',
            headerName: 'Dimension Definition',
            width: 250,
            editable: false,
          },
          {
            field: 'Add',
            headerName: 'Add',
            renderCell: (params) => {
                return <Chip variant="outlined" size="small" {...getChipProps(params)} />;
            },
            width: 150,
            editable: false,
          }
        ];


        function checkIfDimensionIsNotAdded(dimension) {
        if(addedDimensions.some(e => e.dimensionAlias === dimension)) {
            return false
        } else {return true}
        }

        const [dimensionsArray, setDimensionsArray] = useState(initialDimensions);
        const dimArray = dimensionsArray.filter((dimension) => checkIfDimensionIsNotAdded(dimension.dimensionAlias))
        let filteredDimensions = dimArray.filter(dimension => {
            return dimension.testTypes.length === 0 || dimension.testTypes.indexOf(formData.testType) !== -1
        })
        const detailsrows = filteredDimensions.map((row, index) => {

        return {
            id: row.id + 1,
            dimensionAlias: row.dimensionAlias,
            dimensionDefinition: row.dimensionDefinition,
            Add: row.action,
        }
        })


        function handleAdding(params) {
            var copyDimensionsArray = [...dimensionsArray];
            const dimension = copyDimensionsArray.find( dimension => dimension.dimensionAlias === params.row.dimensionAlias)
            const itemIndex = copyDimensionsArray.indexOf(dimension)
            copyDimensionsArray.splice(itemIndex, 1);
            setDimensionsArray(copyDimensionsArray);
            console.log(params.row.dimensionAlias)
            dispatch(addDimension(params.row));
        }


        return (
            <Grid container>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h2" gutterBottom component="div">
                            Add a standard dimension
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <DataGrid
                        autoHeight
                        rows={detailsrows}
                        columns={columns}
                        onCellClick={handleAdding}
                        initialState = {
                            {
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                    },
                                },
                            }
                        }
                        />
                    </Box>
                </Grid>
                        <Grid item xs={6} paddingTop={4} paddingBottom={4}>
          <Box>
            <Button component={NavLink} to="/CustomAnalysis/" preset="callToAction">Home</Button>
          </Box>
        </Grid>
            </Grid>
        )
}


