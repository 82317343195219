import React, { useState }  from "react";
import {
	Box
} from "@coolblue-development/becky";
import {red, green, blue, gray, peppermint, antarcticblue} from '../../SharedComponents/Colors'
import TestDetails from "../Components/TestDetails";


export default function Details() {

    const [open, setOpen] = useState(false);
    const [content, setContent] = useState('');
    const handleClose = (): void => {
      setOpen(false);
    };
    return (
        <Box>
            <TestDetails />
        </Box>
    );
}